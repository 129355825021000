<template>
	<b-card>
		<!-- form -->
	<b-overlay :show="isProfileUpdating" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
		<validation-observer ref="profilePasswordForm">
			<b-form @submit.prevent="profilePasswordValidationForm">
				<b-row>
					<!-- old password -->
					<b-col md="6">
						<b-form-group label="Old Password" label-for="account-old-password">
							<validation-provider #default="{ errors }" name="Old Password" vid="old-password" rules="required" :custom-messages="{ required: 'Please enter old password' }">
								<b-input-group class="input-group-merge">
									<b-form-input id="account-old-password" v-model="passwordValueOld" name="old-password" :type="passwordFieldTypeOld"  placeholder="Old Password" />
									<b-input-group-append is-text>
										<feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<!--/ old password -->
				</b-row>
				<b-row>
					<!-- new password -->
					<b-col md="6">
						<b-form-group label-for="account-new-password" label="New Password">
							<validation-provider #default="{ errors }" name="New Password" vid="new-password" rules="required" :custom-messages="{ required: 'Please enter new password' }">
								<b-input-group class="input-group-merge">
									<b-form-input id="account-new-password" v-model="newPasswordValue" name="new-password" :type="passwordFieldTypeNew"  placeholder="New Password" />
									<b-input-group-append is-text>
										<feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<!--/ new password -->

					<!-- retype password -->
					<b-col md="6">
						<b-form-group label-for="account-retype-new-password" label="Retype New Password">
							<validation-provider #default="{ errors }" name="Confirm Password" vid="confirm-password" rules="required|confirmed:new-password" :custom-messages="{ required: 'Please retype new password', confirmed:'Please make sure your password should match'}">
								<b-input-group class="input-group-merge">
									<b-form-input id="account-retype-new-password" v-model="RetypePassword" name="retype-password" :type="passwordFieldTypeRetype"  placeholder="New Password" />
									<b-input-group-append is-text>
										<feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<!--/ retype password -->

					<!-- buttons -->
					<b-col cols="12">
						<b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1">
							Save changes
						</b-button>
						<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1">
							Reset
						</b-button>
					</b-col>
					<!--/ buttons -->
				</b-row>
			</b-form>
		</validation-observer>
	</b-overlay>
	</b-card>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import {
		BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BOverlay,
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { required } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, ValidationProvider, ValidationObserver, BOverlay,
		},
		directives: {
			Ripple,
		},
		props: {
			generalData: {
				type: Object,
				default: () => { },
			},
		},
		data() {
			return {
				passwordValueOld: '',
				newPasswordValue: '',
				RetypePassword: '',
				passwordFieldTypeOld: 'password',
				passwordFieldTypeNew: 'password',
				passwordFieldTypeRetype: 'password',
				required,
				isProfileUpdating : false,
			}
		},
		computed: {
			passwordToggleIconOld() {
				return this.passwordFieldTypeOld === 'password' ? 'EyeOffIcon' : 'EyeIcon'
			},
			passwordToggleIconNew() {
				return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon' : 'EyeIcon'
			},
			passwordToggleIconRetype() {
				return this.passwordFieldTypeRetype === 'password' ? 'EyeOffIcon' : 'EyeIcon'
			},
		},
		methods: {
			togglePasswordOld() {
				this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
			},
			togglePasswordNew() {
				this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
			},
			togglePasswordRetype() {
				this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
			},
			profilePasswordValidationForm() {
				this.$refs.profilePasswordForm.validate().then(success => {
					if (success) {
						this.isProfileUpdating = true;
						this.$authAPI.profile('update/password', {
							'id': this.generalData.id,
							'currentPassword': this.passwordValueOld,
							'newPassword': this.newPasswordValue,
							'repeatPassword': this.RetypePassword,
						}).then((response) => {
							this.isProfileUpdating = false;
							response = response.data;
							if (response.status) {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
		},
	}
</script>