<template>
	<b-card>
		<!-- media -->
	<b-overlay :show="isProfileUpdating" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
		<b-media no-body>

			<b-media-aside>
				<b-link>
					<b-img ref="previewEl" rounded :src="previewUploadImage" height="80" />
				</b-link>
				<!--/ avatar -->
			</b-media-aside>

			<b-media-body class="mt-75 ml-75">
				<!-- upload button -->
				<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75" @click="$refs.refInputEl.$el.click()">
					Upload
				</b-button>
				<b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif" :hidden="true" plain @change="previewImage" />
				<!--/ upload button -->

				<!-- reset -->
				<!-- <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" size="sm" class="mb-75 mr-75">
					Reset
				</b-button> -->
				<!--/ reset -->
				<b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
			</b-media-body>
		</b-media>
		<!--/ media -->

		<!-- form -->
		<validation-observer ref="profileGeneralForm">
			<b-form class="mt-2" @submit.prevent="profileGeneralValidationForm">
				<b-row>
					<b-col sm="6">
						<b-form-group label="FirstName" label-for="account-firstName">
							<validation-provider #default="{ errors }" name="FirstName" rules="required" :custom-messages="{ required: 'Please enter first name'}">
								<b-form-input v-model="profileUser.firstName" name="firstName" placeholder="Enter FirstName" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="LastName" label-for="account-lastName">
							<validation-provider #default="{ errors }" name="LastName" rules="required" :custom-messages="{ required: 'Please enter last name'}">
								<b-form-input v-model="profileUser.lastName" name="lastName" placeholder="Enter LastName" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<b-form-group label="E-mail" label-for="account-e-mail">
							<validation-provider #default="{ errors }" name="Email" rules="required|email" :custom-messages="{ required: 'Please enter email', email: 'Please enter valid email' }">
								<b-form-input v-model="profileUser.email" name="email" placeholder="Enter Email" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col cols="12">
						<b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-2 mr-1">
							Save changes
						</b-button>
					</b-col>
				</b-row>
			</b-form>
		</validation-observer>
	</b-overlay>

	</b-card>
</template>

<script>
	import { ValidationProvider, ValidationObserver } from 'vee-validate'
	import {
		BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BOverlay,
	} from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'
	import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
	import { ref } from '@vue/composition-api'
	import { required } from '@validations'
	import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
		components: {
			BButton, BForm, BImg, BFormFile, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, ValidationProvider, ValidationObserver, BOverlay,
		},
		directives: {
			Ripple,
		},
		props: {
			generalData: {
				type: Object,
				default: () => { },
			},
		},
		data() {
			return {
				profileUser: {
					'firstName': this.generalData.firstName,
					'lastName': this.generalData.lastName,
					'email': this.generalData.email,
					'photo_thumb': this.generalData.photo_thumb,
				},
				profileFile: '',
				required,
				isProfileUpdating : false,
			}
		},
		computed: {
			previewUploadImage() {
				if (this.profileFile) {
					return this.profileFile;
				}
				return this.$root.getUploadFilePath(`user/${this.profileUser.photo_thumb}`);
			}
		},
		methods: {
			resetForm() {
				this.profileUser = {
					"firstName": null,
					"lastName": null,
					"email": null,
				};
				this.profileFile = '';
			},
			profileGeneralValidationForm() {
				const fileInput = this.$refs['refInputEl'];
				const fileSizeLimit = 800000;

				if (fileInput.files.length > 0 && fileInput.files[0].size >= fileSizeLimit) {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Notification',
							icon: 'BellIcon',
							variant: 'danger',
							text: 'Image size should be up to 800 KB',
						},
					});
					return;
				}
				if (fileInput.files.length > 0 && !fileInput.files[0].type.startsWith("image/")) {
					this.$toast({
						component: ToastificationContent,
							props: {
								title: 'Notification',
								icon: 'BellIcon',
								variant: 'danger',
								text: 'Allowed JPG, GIF or PNG',
							},
						});
					return;
				}
				this.isProfileUpdating = true; 
				this.$refs.profileGeneralForm.validate().then(success => {
					if (success) {
						this.$authAPI.profile('update/user', {
							'id': this.generalData.id,
							'firstName': this.profileUser.firstName,
							'lastName': this.profileUser.lastName,
							'email': this.profileUser.email,
							'photo': this.profileFile,
						}).then((response) => {
							this.isProfileUpdating = false;
							response = response.data;
							if (response.status) {
								this.$root.authUserObject.firstName = this.profileUser.firstName;
								this.$root.authUserObject.lastName = this.profileUser.lastName;
								this.$root.authUserObject.email = this.profileUser.email;
								this.$root.authUserObject.photo_thumb = response.profile_image;
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'success',
										text: response.message,
									},
								});
							} else {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'Notification',
										icon: 'BellIcon',
										variant: 'danger',
										text: response.message,
									},
								});
							}
						});
					}
				})
			},
			previewImage: function (event) {
				let input = event.target;
				if (input.files && input.files[0]) {
					let reader = new FileReader();
					reader.onload = (e) => {
						this.profileFile = e.target.result;
					}
					reader.readAsDataURL(input.files[0]);
				}
			}
		},
		setup() {
			const refInputEl = ref(null)
			const previewEl = ref(null)

			const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

			return {
				refInputEl,
				previewEl,
				inputImageRenderer,
			}
		},
	}
</script>